<template>
  <div id="formio">

    </div>


</template>

<script>
  export default {
    name: 'ShiftForm',
    mounted(){
      Formio.createForm(document.getElementById('formio'),
        {
          "display": "form",
          "components": [
            {
              "label": "Panel",
              "title": "Shift Information",
              "theme": "success",
              "collapsible": false,
              "mask": false,
              "tableView": false,
              "alwaysEnabled": false,
              "type": "panel",
              "input": false,
              "key": "panel2",
              "conditional": {
                "show": "",
                "when": "",
                "json": ""
              },
              "components": [
                {
                  "label": "Name",
                  "allowMultipleMasks": false,
                  "showWordCount": false,
                  "showCharCount": false,
                  "tableView": true,
                  "alwaysEnabled": false,
                  "type": "textfield",
                  "input": true,
                  "key": "name",
                  "defaultValue": "",
                  "validate": {
                    "customMessage": "",
                    "json": ""
                  },
                  "conditional": {
                    "show": "",
                    "when": "",
                    "json": ""
                  },
                  "widget": {
                    "type": ""
                  },
                  "reorder": false,
                  "inputFormat": "plain",
                  "encrypted": false,
                  "properties": {},
                  "customConditional": "",
                  "logic": []
                },
                {
                  "label": "Type",
                  "mask": false,
                  "tableView": true,
                  "alwaysEnabled": false,
                  "type": "select",
                  "input": true,
                  "key": "type",
                  "defaultValue": "1",
                  "validate": {
                    "select": false,
                    "customMessage": "",
                    "json": ""
                  },
                  "conditional": {
                    "show": "",
                    "when": "",
                    "json": ""
                  },
                  "data": {
                    "values": [
                      {
                        "label": "Work",
                        "value": "1"
                      },
                      {
                        "label": "Rest",
                        "value": "2"
                      },
                      {
                        "label": "WeeklyOff",
                        "value": "3"
                      }
                    ]
                  },
                  "valueProperty": "value",
                  "selectThreshold": 0.3,
                  "encrypted": false,
                  "properties": {},
                  "customConditional": "",
                  "logic": [],
                  "reorder": false,
                  "lazyLoad": false,
                  "selectValues": "",
                  "disableLimit": false,
                  "sort": "",
                  "reference": false
                },
                {
                  "label": "Columns",
                  "columns": [
                    {
                      "components": [],
                      "width": 4,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column8",
                      "tableView": true,
                      "label": ""
                    },
                    {
                      "components": [
                        {
                          "label": "In Time",
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "time",
                          "input": true,
                          "key": "in_time",
                          "defaultValue": "",
                          "validate": {
                            "customMessage": "",
                            "json": ""
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "inputMask": "99:99",
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": [],
                          "tags": [],
                          "reorder": false
                        }
                      ],
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column9",
                      "tableView": true,
                      "label": ""
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column1",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Break Out",
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "time",
                          "input": true,
                          "key": "break_out",
                          "defaultValue": "",
                          "validate": {
                            "customMessage": "",
                            "json": ""
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "inputMask": "99:99",
                          "properties": {},
                          "tags": [],
                          "reorder": false,
                          "encrypted": false,
                          "customConditional": "",
                          "logic": []
                        }
                      ]
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column2",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Break In",
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "time",
                          "input": true,
                          "key": "break_in",
                          "defaultValue": "",
                          "validate": {
                            "customMessage": "",
                            "json": ""
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "inputMask": "99:99",
                          "properties": {},
                          "tags": [],
                          "reorder": false,
                          "encrypted": false,
                          "customConditional": "",
                          "logic": []
                        }
                      ]
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column3",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Out",
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "time",
                          "input": true,
                          "key": "out_time",
                          "defaultValue": "",
                          "validate": {
                            "customMessage": "",
                            "json": ""
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "inputMask": "99:99",
                          "properties": {},
                          "tags": [],
                          "reorder": false,
                          "encrypted": false,
                          "customConditional": "",
                          "logic": []
                        }
                      ]
                    }
                  ],
                  "mask": false,
                  "tableView": false,
                  "alwaysEnabled": false,
                  "type": "columns",
                  "input": false,
                  "key": "columns2",
                  "conditional": {
                    "show": "",
                    "when": "",
                    "json": ""
                  },
                  "properties": {},
                  "customConditional": "",
                  "logic": [],
                  "reorder": false
                },
                {
                  "label": "Columns",
                  "columns": [
                    {
                      "components": [],
                      "width": 4,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column8",
                      "tableView": true,
                      "label": ""
                    },
                    {
                      "components": [
                        {
                          "label": "Check In",
                          "shortcut": "",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "checkbox",
                          "input": true,
                          "key": "checkIn",
                          "defaultValue": false,
                          "validate": {
                            "customMessage": "",
                            "json": ""
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "reorder": false,
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": []
                        }
                      ],
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column9",
                      "tableView": true,
                      "label": ""
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column1",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Breake Out",
                          "shortcut": "",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "checkbox",
                          "input": true,
                          "key": "breakeOut",
                          "defaultValue": false,
                          "validate": {
                            "customMessage": "",
                            "json": ""
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "reorder": false,
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": []
                        }
                      ]
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column2",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Breake In",
                          "shortcut": "",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "checkbox",
                          "input": true,
                          "key": "breakeIn",
                          "defaultValue": false,
                          "validate": {
                            "customMessage": "",
                            "json": ""
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": [],
                          "tags": [],
                          "reorder": false
                        }
                      ]
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column3",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Check Out",
                          "shortcut": "",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "checkbox",
                          "input": true,
                          "key": "out",
                          "defaultValue": false,
                          "validate": {
                            "customMessage": "",
                            "json": ""
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": [],
                          "reorder": false
                        }
                      ]
                    }
                  ],
                  "mask": false,
                  "tableView": false,
                  "alwaysEnabled": false,
                  "type": "columns",
                  "input": false,
                  "key": "columns3",
                  "conditional": {
                    "show": "",
                    "when": "",
                    "json": ""
                  },
                  "properties": {},
                  "customConditional": "",
                  "logic": [],
                  "reorder": false
                },
                {
                  "label": "Columns",
                  "columns": [
                    {
                      "components": [
                        {
                          "label": "In Grace",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "number",
                          "input": true,
                          "key": "inGrace",
                          "validate": {
                            "customMessage": "",
                            "json": "",
                            "min": 0
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "delimiter": false,
                          "requireDecimal": false,
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": [],
                          "reorder": false
                        }
                      ],
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column8",
                      "tableView": true,
                      "label": ""
                    },
                    {
                      "components": [
                        {
                          "label": "Out Grace",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "number",
                          "input": true,
                          "key": "outGrace",
                          "validate": {
                            "customMessage": "",
                            "json": "",
                            "min": 0
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "delimiter": false,
                          "requireDecimal": false,
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": [],
                          "reorder": false
                        }
                      ],
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column9",
                      "tableView": true,
                      "label": ""
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column1",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Min Punch",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "number",
                          "input": true,
                          "key": "minPunch",
                          "validate": {
                            "customMessage": "",
                            "json": "",
                            "min": 0,
                            "max": 6
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "delimiter": false,
                          "requireDecimal": false,
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": [],
                          "reorder": false
                        }
                      ]
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column2",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Min In Minutes",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "number",
                          "input": true,
                          "key": "minInMinutes",
                          "validate": {
                            "customMessage": "",
                            "json": "",
                            "min": 0
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "delimiter": false,
                          "requireDecimal": false,
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": [],
                          "reorder": false
                        }
                      ]
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column3",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Max Out Minutes",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "number",
                          "input": true,
                          "key": "maxOutMinutes",
                          "validate": {
                            "customMessage": "",
                            "json": "",
                            "min": 0
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "delimiter": false,
                          "requireDecimal": false,
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": [],
                          "reorder": false
                        }
                      ]
                    },
                    {
                      "width": 2,
                      "offset": 0,
                      "push": 0,
                      "pull": 0,
                      "type": "column",
                      "hideOnChildrenHidden": false,
                      "input": true,
                      "key": "column4",
                      "tableView": true,
                      "label": "",
                      "components": [
                        {
                          "label": "Fixed Lunch",
                          "labelPosition": "top",
                          "shortcut": "",
                          "mask": false,
                          "tableView": true,
                          "alwaysEnabled": false,
                          "type": "checkbox",
                          "input": true,
                          "key": "fixedLunch",
                          "defaultValue": false,
                          "validate": {
                            "customMessage": "",
                            "json": ""
                          },
                          "conditional": {
                            "show": "",
                            "when": "",
                            "json": ""
                          },
                          "encrypted": false,
                          "properties": {},
                          "customConditional": "",
                          "logic": [],
                          "reorder": false
                        }
                      ]
                    }
                  ],
                  "mask": false,
                  "tableView": false,
                  "alwaysEnabled": false,
                  "type": "columns",
                  "input": false,
                  "key": "columns4",
                  "conditional": {
                    "show": "",
                    "when": "",
                    "json": ""
                  },
                  "reorder": false,
                  "properties": {},
                  "customConditional": "",
                  "logic": []
                }
              ],
              "collapsed": false,
              "reorder": false,
              "properties": {},
              "customConditional": "",
              "logic": []
            },
            {
              "type": "button",
              "label": "Submit",
              "key": "submit",
              "disableOnInvalid": true,
              "theme": "success",
              "input": true,
              "tableView": true
            }
          ]
        }).then(function(form) {
        form.on('submit', function(submission) {
          console.log(submission);
        });
      });
      Formio.icons = 'fontawesome';
    }
  }
</script>

<style scoped>

</style>
